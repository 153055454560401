<template>
  <div>
    <section class="s-cart">
      <div class="container">
        <h1>Корзина</h1>

        <div class="cart" v-if="products">
          <div
            v-for="(product, index) of products"
            :key="index"
            :product-id="product.id"
            :class="{ disabled: !product.isChecked }"
            class="cart-item display--flex"
          >
            <div class="cart-item--left display--flex">
              <div class="cart-item--title display--flex">
                <input
                  :name="`input${product.id}`"
                  :id="`input${product.id}`"
                  type="checkbox"
                  :checked="product.isChecked"
                  @change="checkCount(product.id)"
                />
                <label class="cart-label" :for="`input${product.id}`"></label>
                <router-link :to="`/product/${product.slug}`">{{
                  product.name
                }}</router-link>
              </div>
            </div>
            <div class="cart-item--right display--flex">
              <div class="cart-item--price">{{ product.price }} Р.</div>
              <div class="cart-item--control display--flex">
                <button
                  type="button"
                  class="btn-minus"
                  @click="changeCount(-1, index)"
                ></button>
                <input
                  type="text"
                  min="0"
                  max="1000"
                  disabled
                  :value="product.product_count"
                />
                <button
                  type="button"
                  class="btn-plus"
                  @click="changeCount(1, index)"
                ></button>
              </div>
              <div class="cart-item--cost">{{ product.totalPrice }} Р.</div>
              <button
                type="button"
                @click="deleteProduct(product)"
                class="cart-item--remove"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M4 4L12 12M20 20L12 12M12 12L20 4M12 12L4 20"
                    stroke="currentColor"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div class="cart-total display--flex">
            <div class="cart-total--left">Всего товаров:</div>
            <div class="cart-total--right display--flex">
              <div class="total-items">{{ generalCount }}</div>
              <div class="total-cost">{{ generalPrice }} Р.</div>
            </div>
          </div>
          <div class="cart-action btn-action display--flex">
            <button
              :disabled="basketCount < 0"
              @click="sendApprove"
              type="button"
              class="btn btn-accent"
            >
              Перейти к оформлению
            </button>
          </div>
        </div>
      </div>
    </section>

    <product-slider
      title="Вам может подойти"
    ></product-slider>
  </div>
</template>

<script>
import {
  onBeforeMount,
  onBeforeUpdate,
  ref,
} from "@vue/runtime-core";
import ProductSlider from "../../components/sliders/product-slider/ProductSlider.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "cart-page",
  components: { ProductSlider },
  setup() {
    let store = useStore(),
      router = useRouter();

    let generalPrice = ref(0),
      generalCount = ref(0),
      products = ref([]),
      basketCount = ref(0);

    onBeforeMount(async () => {
      let listProducts = JSON.parse(localStorage.getItem("cartProducts"));
      if(listProducts && listProducts.length > 0){
        let listIds = listProducts.map((product) => product.id)
        await store.dispatch("products/getProductsForBusket", listIds)
        let productsForBusket = store.state.products.productsForBusket;
        productsForBusket.forEach((product) => {
          let oldProduct = listProducts.find((pr) => pr.id == product.id)
          let newProduct = {
            id: product.id,
            price: product.price,
            name: product.name,
            product_count: oldProduct.product_count,
            totalPrice: product.price * oldProduct.product_count,
            isChecked: oldProduct.isChecked
          }
          
          products.value.push(newProduct)
        })
      }

      store.dispatch("products/getPopularProducts");
    });
    onBeforeUpdate(() => {
      if (products.value) {
        localStorage.setItem(
          "product",
          JSON.stringify(products.value.filter((pr) => pr.isChecked))
        );
        generalPriceCount();
      }
      
    });

    let checkCount = (id) => {
      let count;
      let price;

      products.value.map((item) => {
        if (item.id === id) {
          if (item.isChecked) {
            item.isChecked = !item.isChecked;
            count = generalCount.value - item.product_count;
            price = generalPrice.value - item.totalPrice;

            generalCount.value = count;
            generalPrice.value = price;
          } else {
            item.isChecked = !item.isChecked;
            count = generalCount.value + item.product_count;
            price = generalPrice.value + item.totalPrice;

            generalCount.value = count;
            generalPrice.value = price;
          }
          localStorage.setItem("cartProducts", JSON.stringify(products.value));
        }
      });
    };
    let changeCount = (num, index) => {
      if (products.value[index].product_count === 1 && num === -1) return;
      products.value[index].product_count += num;

      if (num === 1) {
        products.value[index].totalPrice += products.value[index].price;
      } else {
        products.value[index].totalPrice -= products.value[index].price;
      }
    };
    let generalPriceCount = () => {
      generalPrice.value = 0;
      generalCount.value = 0;
      basketCount.value = 0;

      if (products.value) {
        products.value.forEach((item) => {
          if (item.isChecked) {
            generalPrice.value += parseInt(item.totalPrice);
            generalCount.value += parseInt(item.product_count);
            basketCount.value += parseInt(item.product_count);
          }
        });
        localStorage.setItem("generalPrice", generalPrice.value);
        localStorage.setItem("basketCount", basketCount.value);

        localStorage.setItem("cartProducts", JSON.stringify(products.value));
        store.commit("products/setBasketProducts", basketCount.value);
      }
    };
    let deleteProduct = (product) => {
      products.value = products.value.filter((item) => item.id !== product.id);
    };
    let sendApprove = () => {
      if (
        localStorage.getItem("basketCount", JSON.stringify(products.value)) !==
          0 &&
        localStorage.getItem("basketCount", JSON.stringify(products.value)) > 0
      ) {
        localStorage.setItem(
          "product",
          JSON.stringify(products.value.filter((pr) => pr.isChecked))
        );
        router.push("order");
      }
    };

    return {
      generalPrice,
      generalCount,
      products,
      basketCount,
      checkCount,
      changeCount,
      generalPriceCount,
      deleteProduct,
      sendApprove
    };
  },
};
</script>

<style scoped lang="scss">
.s-cart {
  padding: 150px 0 60px;

  @media(max-width: 767px){
    padding: 70px 0 60px;
  }
}

.cart {
  padding: 35px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.cart-item {
  align-items: center;
  justify-content: space-between;
  gap: 35px;
}

.cart-item.disabled {
  opacity: 0.7;
}

.cart-item + .cart-item {
  margin-top: 35px;
}

.cart-item--left {
  flex: 0 0 50%;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 18px;
  line-height: 22px;
}

.cart-item--title {
  flex: 5;
  align-items: center;
}

.cart-item--title a {
  color: var(--color);
}

.cart-item--title a:hover {
  color: var(--accent);
}

.cart-item--title input {
  width: 0;
  height: 0;
}

.cart-item--title label {
  position: relative;
  display: block;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  border: 1px solid var(--border-color);
  border-radius: 2px;
}

.cart-item--title label::before {
  position: absolute;
  display: none;
  content: "";
  top: 5px;
  left: 4px;
  width: 13px;
  height: 8px;
  border: none;
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  transform: rotate(-45deg);
}

.cart-item--title input:checked ~ label {
  background: var(--accent);
  border-color: var(--accent);
}

.cart-item--title input:checked ~ label:before {
  display: block;
}

.cart-item--right {
  flex: auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 35px;
}

.cart-item--price {
  flex: 0 0 100px;
  text-align: right;
  white-space: nowrap;
}

.cart-item--control {
  position: relative;
  flex: 0 0 140px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 140px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
}

.cart-item--control button {
  display: inline-block;
  width: 35px;
  height: 40px;
  padding: 10px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.cart-item--control .btn-minus {
  background-image: url(./../../assets/images/icons/minus-icon.svg);
}

.cart-item--control .btn-plus {
  background-image: url(./../../assets/images/icons/plus-icon.svg);
}

.cart-item--control input {
  width: 55px;
  height: 40px;
  padding: 10px 5px;
  background: none;
  border: none;
  box-shadow: none;
  text-align: center;
}

.cart-item--control button:hover {
  opacity: 0.7;
}

.cart-item--cost {
  flex: 0 0 100px;
  text-align: right;
  white-space: nowrap;
}

.cart-item--remove {
  display: flex;
  flex: 0 0 24px;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 0;
  color: var(--color);
  background: transparent;
  border: none;
  box-shadow: none;
  text-align: right;
  cursor: pointer;
}

.cart-item--remove:hover {
  color: red;
}

.cart-label {
  cursor: pointer;
}

.cart-total {
  justify-content: space-between;
  margin-top: 35px;
  padding-top: 35px;
  border-top: 1px solid var(--border-color);
  font-size: 24px;
  line-height: 29px;
}

.cart-total--left {
  flex: 0 0 60%;
}

.cart-total--right {
  flex: 0 0 26%;
  justify-content: space-between;
  padding-right: 59px;
  text-align: right;
}

.cart-total .total-items {
  text-align: left;
}

.cart-total .total-cost {
  font-weight: 400;
}

.cart-action {
  justify-content: flex-end;
  margin-top: 35px;
}

@media (max-width: 992px) {
  .cart {
    padding: 20px;
  }

  .cart-item {
    position: relative;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
  }

  .cart-item + .cart-item {
    margin-top: 20px;
  }

  .cart-item--left {
    flex: 1;
    width: 100%;
    padding-right: 24px;
    gap: 8px;
  }

  .cart-item--right {
    flex: 1;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  }

  .cart-item--price {
    display: none;
  }

  .cart-item--cost {
    font-weight: 400;
  }

  .cart-item--remove {
    position: absolute;
    top: 10%;
    right: 0;
    height: 22px;
    width: 22px;
  }

  .cart-total {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    padding-top: 20px;
    border-top: none;
  }

  .cart-total--left {
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
  }

  .cart-total--right {
    flex: 1;
    justify-content: space-between;
    width: 100%;
    padding-right: 0;
  }

  .cart-action {
    margin-top: 20px;
  }

  .cart-action .btn {
    width: 100%;
    padding: 12px 18px 14px;
  }
}
</style>